@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .h1 {
    @apply text-4xl md:text-7xl leading-tightest tracking-h1-sm md:tracking-h1 pb-3 font-medium;
  }
  .h2 {
    @apply text-3xl md:text-6xl leading-tighter tracking-h1-sm md:tracking-h1 font-medium;
  }
  .h3 {
    @apply text-xl md:text-5xl leading-normal tracking-tightest font-medium;
  }
  .h4 {
    @apply text-lg md:text-4xl leading-relaxed tracking-tightest font-medium;
  }
  .h5 {
    @apply text-md md:text-2xl leading-loose tracking-tighter font-medium;
  }
  .mono-large {
    @apply font-mono text-sm leading-normal tracking-loose uppercase font-normal;
  }
  .mono-small {
    @apply font-mono text-xs leading-lg tracking-loose uppercase font-normal;
  }
  .body-large {
    @apply text-sm md:text-25 leading-loose tracking-normal font-normal;
  }
  .body-small {
    @apply text-16 md:text-21 leading-loose tracking-normal font-medium;
  }

  .body-xs {
    @apply text-16 leading-loose tracking-normal;
  }

  .body-xxs {
    @apply text-xs leading-loose tracking-normal;
  }

  .text-gradient {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-gradient-a to-gradient-b to-90%;
  }
  .text-gradient-50 {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-gradient-a to-gradient-b to-50%;
  }
  .text-gradient-grey {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-white to-grey;
  }
  .bg-gradient-blur {
    @apply bg-gradient absolute rounded-full w-full min-w-96 max-w-4xl left-1/2 top-1/2 -z-10 transform -translate-x-1/2 -translate-y-1/2 blur-3xl opacity-50 pointer-events-none aspect-square;
  }
  .bg-gradient-blur-black {
    @apply bg-gradient-black absolute rounded-full h-full w-full left-1/2 top-1/2 -z-10 transform -translate-x-1/2 -translate-y-1/2 blur-3xl opacity-50 pointer-events-none;
  }
  .footer-nav-item {
    @apply text-white text-16 md:body-small hover:text-green transition-colors duration-200 ease-in-out items-start;
  }

  .grid-padding-left {
    @media screen(xl) {
      padding-left: calc((100vw - 1920px) / 2);
    }
  }

  .grid-padding-right {
    @apply pr-4 md:pr-16;
    @media screen(xl) {
      padding-right: calc((100vw - 1920px) / 2);
    }
  }

  .filter-grid {
    @apply grid max-w-1920 mx-auto mt-3 px-4 md:px-16;
    grid-template-columns: 1fr 0fr;

    &.is-expanded {
      grid-template-columns: 0fr 1fr;
    }
  }

  .bg-gradient {
    background: radial-gradient(theme(colors.green), transparent 50%);
  }

  .counter-gradient {
    background: radial-gradient(theme(colors.green), #181526);
  }

  .bg-gradient-black {
    background: radial-gradient(theme(colors.black), transparent);
  }

  .bg-light-leak {
    background:
      url('/images/light-leak.svg') no-repeat top left,
      theme('colors.off-black');
    background-size: cover;
    z-index: -100;
  }

  .card-bg {
    background: linear-gradient(
      to bottom,
      theme('colors.lime') 0%,
      theme('colors.green') 100%
    );
  }

  .hero-gradient {
    background: radial-gradient(
      ellipse 40% 50% at 50% 50%,
      theme('colors.off-black') 0%,
      transparent 50%
    );
  }

  .bg-mobile-nav {
    background: linear-gradient(
      90deg,
      theme('colors.gradient-a') 0%,
      theme('colors.gradient-b') 100%
    );
  }

  .left-fade-out {
    background: linear-gradient(
      90deg,
      theme('colors.black') 0%,
      theme('colors.transparent') 100%
    );
  }

  .green-fade-out {
    background: linear-gradient(
      180deg,
      theme('colors.green') 0%,
      theme('colors.transparent') 100%
    );
  }

  .blue-fade-out {
    background: linear-gradient(
      180deg,
      #13131b 0%,
      theme('colors.off-black') 100%
    );
  }

  .bg-section-nav {
    background: linear-gradient(
      180deg,
      theme('colors.black') 0%,
      transparent 100%
    );
  }

  .bg-gradient-switch-border {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border: 1px solid transparent;
      border-radius: 9999px;
      background: linear-gradient(101.9deg, #16601b 19.59%, #053027 97.89%)
        border-box;
      mask:
        linear-gradient(#fff 100px 0) padding-box,
        linear-gradient(#fff 0 0);
      mask-composite: exclude;
    }
  }

  .wrap-anywhere {
    overflow-wrap: anywhere;
  }

  .bg-gradient-switch {
    background: linear-gradient(
      0deg,
      rgba(16, 214, 107, 0.02) -86.46%,
      rgba(76, 192, 84, 0.01) -45.67%,
      rgba(76, 192, 84, 0.3) 117.5%
    );
  }

  .card-border-gradient {
    background-image: conic-gradient(
      from 0deg at 50% 50%,
      theme(colors.card-border-gradient-b) 0%,
      theme(colors.card-border-gradient-a) 25%,
      theme(colors.card-border-gradient-b) 50%,
      theme(colors.card-border-gradient-a) 75%,
      theme(colors.card-border-gradient-b) 100%
    );
  }

  .deploy-body strong {
    font-weight: 600;
    color: theme(colors.white);
  }

  .encryption-body strong {
    font-weight: 400;
    color: theme(colors.white);
  }

  .gpu-body strong {
    font-weight: 400;
    color: theme(colors.white);
  }

  .deployment-image-translate {
    transform: translateX(-10%);
  }

  .minimal-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: theme(colors.grey) transparent;

    ::-webkit-scrollbar {
      width: 10px;
    }

    ::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: theme(colors.grey);
      outline: 0;
      border: 0;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: theme(colors.grey);
    }
  }

  .hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  .grid-pile > * {
    grid-area: 1 / 1;
  }

  .gpu-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    @apply grid max-w-1920 gap-3 mx-auto mt-3 px-4 md:px-16 transition-all duration-1500 ease-out-expo delay-100;

    @media screen(lg) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      grid-template-rows: fit-content(100%);

      & > div {
        @apply min-w-[auto];
      }

      & > div:first-child {
        @apply duration-100 delay-0;
        > div:first-child {
          @apply opacity-100 duration-100 ease-out-expo delay-0;
        }

        h4,
        .mono-large {
          @apply opacity-100 duration-100 ease-in-out delay-1500;
        }
      }

      &.expand-right {
        grid-template-columns: minmax(0, 0fr) minmax(0, 1fr);
        @apply gap-0;

        & > div:first-child {
          @apply opacity-0 transition-opacity duration-1000 ease-in-out delay-700;

          > div:first-child {
            @apply opacity-0 duration-300 ease-in-out delay-0;
          }

          h4,
          .mono-large {
            @apply opacity-0 duration-300 ease-in-out delay-1500;
          }
        }
      }
    }

    & > div:first-child {
      @apply transition-opacity duration-1000 ease-in-out;

      & > div:first-child {
        @apply transition-opacity duration-1000 ease-in-out;
      }
    }
  }

  .gpu-toggle-grid {
    grid-template-rows: 1fr 2.5rem;
    @apply grid transition-all duration-1000 ease-in-out h-full;

    .gpu-grid.expand-right & {
      grid-template-rows: 1fr 1rem;
    }

    @media screen(lg) {
      grid-template-rows: 1fr 4rem;

      .gpu-grid.expand-right & {
        grid-template-rows: 1fr 4rem;
      }
    }
  }

  .portable-text {
    ul {
      @apply list-disc pl-5 mx-0 mt-4;
    }

    li {
      @apply mb-2;
    }
  }

  html {
    .swiper {
      margin-left: 0 !important;
      .swiper-slide:not(:last-child) {
        @apply max-w-430 w-[320px] sm:w-[430px] h-full flex-shrink-0 flex flex-col gap-3 select-none;
      }
      .swiper-slide:last-child {
        @apply w-12 md:w-32;
        @media screen(xl) {
          width: calc((100vw - 1920px) / 2);
        }
      }
    }
  }

  .hit-box {
    &:after {
      content: '';
      @apply absolute inset-0 -z-10 h-full;
    }
  }

  .card-border-bg {
    background-image: linear-gradient(
      var(--rotate),
      theme(colors.card-border-gradient-b),
      theme(colors.card-border-gradient-a) 70%,
      theme(colors.off-white) 90%,
      theme(colors.lime)
    );
    position: absolute;
    top: -1%;
    left: -2%;
    animation: card-border-spin 2.5s ease-in-out infinite;
  }
}

@property --rotate {
  syntax: '<angle>';
  initial-value: 132deg;
  inherits: false;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-[hsl(var(--border))]; 
  }
  body {
    @apply bg-[hsl(var(--background))] text-[hsl(var(--foreground))];
  }
}
